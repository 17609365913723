<template>
  <layoutContainer activityFrom="公司列表页">
    <div>
      <div class="search-header">
        <div v-if="SearchInput" class="SearchInput">
          {{ SearchInput }}
          <i @click="$store.commit('ChangeSearchInput', '')" :class="'el-icon-error close-icon'"></i>
        </div>
        <checkRadioGroup
          title="承接开发"
          :list="BusinessList.softwareDevelopmentList"
          :activeIndex.sync="queryParams.develop"
          keyname="name"
        ></checkRadioGroup>
        <checkRadioGroupNew
          title="在售产品"
          :list="productList.base"
          :activeItem="productList.findActiveParent(queryParams.product)"
          :selectCategoryName="productList.findCheckedNameById(queryParams.product)"
          :activeIndex.sync="queryParams.product"
          keyname="name"
        >
        </checkRadioGroupNew>
        <checkRadioGroupNew
          title="提供服务"
          :list="serviceList.base"
          :activeItem="serviceList.findActiveParent(queryParams.serve)"
          :selectCategoryName="serviceList.findCheckedNameById(queryParams.serve)"
          :activeIndex.sync="queryParams.serve"
          keyname="name"
        >
        </checkRadioGroupNew>
        <checkRadioGroupNew
          title="所在地区"
          :list="area_list.base"
          :activeItem="area_list.findActiveParent(queryParams.provinceId)"
          :selectCategoryName="area_list.findCheckedNameById(queryParams.provinceId)"
          :activeIndex.sync="queryParams.provinceId"
          keyname="name"
          :areaLevel="true"
        ></checkRadioGroupNew>
      </div>
      <p class="total"></p>
      <div class="current-Type-Sum">
        <div class="sum">
          <span>共</span>
          <span>{{ queryParams.total }}</span>
          <span>公司</span>
        </div>
      </div>
      <div class="conpany-list-box">
        <div class="company-item" v-for="(item, i) in companyList" :key="i">
          <companyListItem :item="item" />
        </div>
      </div>

      <center>
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page.sync="queryParams.page"
          :page-size="queryParams.size"
          :total="queryParams.total"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          @current-change="currentChange"
        >
        </el-pagination>
      </center>
    </div>
  </layoutContainer>
</template>

<script>
import {mapState} from 'vuex'

import {getCompanyListApi, getCompanyCaseDetailApi} from '../../api/company'
import {checkedDeepFormat} from '../../utils/findQueryName'

export default {
  computed: {
    ...mapState([
      'UserInfo',
      'AreaList',
      'BusinessList',
      'CompanyList',
      'EducationList',
      'EmployeeDomainList',
      'SearchInput'
    ]),
    productList() {
      return checkedDeepFormat(this.BusinessList.productList) || {}
    },
    serviceList() {
      return checkedDeepFormat(this.BusinessList.serviceList) || {}
    },
    area_list() {
      return checkedDeepFormat(this.AreaList) || {}
    }
  },
  data() {
    return {
      companyList: [], // 公司列表
      businessId: [], //  0 走所有的业务类型 承接业务id，1-网站建设，2-app开发，3-小程序开发
      queryParams: {
        page: 0, //页码
        size: 8, //页面大小
        total: 100,
        provinceId: 0, //  所在地区id
        develop: '',
        serve: '',
        product: ''
      }
    }
  },
  created() {
    // this.$store.commit('ChangeSearchInput', '')
    this.getCompanyList()
  },
  mounted() {
    // this.getCompanyDetail()
    // this.getCompanyCaseDetail()
  },
  methods: {
    getCompanyList() {
      const {provinceId, page, size} = this.queryParams
      let obj = {page, size}
      if (this.businessId) obj.businessIds = this.businessId.join()
      if (provinceId) obj.provinceId = provinceId
      if (this.SearchInput) obj.keyWord = this.SearchInput
      getCompanyListApi(obj).then(res => {
        this.companyList = [...res.data.records]
        this.queryParams.pages = res.data.current
        this.queryParams.size = res.data.size
        this.queryParams.total = res.data.total
      })
    },

    getCompanyCaseDetail() {
      getCompanyCaseDetailApi({caseId: 1}).then(res => {})
    },
    currentChange(val) {
      this.queryParams.page = val
      this.getCompanyList()
    }
  },
  watch: {
    ['$route.query']: {
      immediate: true,
      handler() {
        const query = this.$route.query
        this.queryParams.provinceId = query.area_id ? query.area_id : 0
        this.queryParams.develop = query.develop_id ? query.develop_id : ''
        this.queryParams.serve = query.service_id ? query.service_id : ''
        this.queryParams.product = query.product_id ? query.product_id : ''
        log.JSON(this.queryParams)
      }
    },
    ['businessId']() {
      this.getCompanyList()
    },
    queryParams: {
      deep: true,
      handler(val) {
        const params = []
        if (val.develop) params.push(val.develop)
        if (val.serve) params.push(val.serve)
        if (val.product) params.push(val.product)
        this.businessId = params
      }
    },
    ['queryParams.provinceId']() {
      this.getCompanyList()
    },
    SearchInput() {
      this.getCompanyList()
    }
  }
}
</script>

<style lang="scss" scoped>
.search-header {
  background: #fff;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 10px 0;
}

// .check-box {
//   display: flex;
//   margin-top: 10px;
//   div {
//     margin-right: 30px;
//   }
//   li {
//     padding: 10px 30px;
//   }
//   .active {
//     background: #55cbc4;
//   }
// }
.conpany-list-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;

  // border: 1px solid #000;
  > :nth-child(4n) {
    margin-left: 20px;
  }

  > :nth-child(4n-3) {
    margin-right: 20px;
  }

  > :nth-child(4n-2) {
    margin-right: 20px;
  }

  .company-item {
    border-radius: 8px;
    height: 350px;
    overflow: hidden;
    background: #fff;
    margin-bottom: 20px;

    img {
      width: 285px;
      height: 200px;
    }

    p {
      padding-left: 20px;
    }

    .title {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      height: 45px;
      line-height: 45px;
    }

    .text {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      height: 50px;
      line-height: 50px;
    }

    .address {
      height: 55px;
      line-height: 55px;
      color: #5ad3cd;
    }
  }
}

.SearchInput {
  display: inline-block;
  margin-left: 160px;
  height: 36px;
  line-height: 36px;
  padding-left: 20px;
  padding-right: 20px;
  background: #5ad3cd;
  color: #fff;
  border-radius: 4px;
  position: relative;
  margin-top: 15px;
  margin-bottom: 15px;

  > i {
    position: absolute;
    color: #666666;
    font-size: 20px;
    right: -30px;
    top: 8px;
  }
}

.total {
  margin: 25px 0;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 25px;
}

.current-Type-Sum {
  height: 28px;
  font-size: 20px;
  font-weight: 500;
  color: #333333;
  margin: 30px 0;

  span {
    display: inline-block;
    padding-left: 6px;

    &:nth-child(2) {
      color: rgba(255, 114, 76, 1);
    }
  }
}
</style>
