import { render, staticRenderFns } from "./companyDetail.vue?vue&type=template&id=7a9194fe&scoped=true&"
import script from "./companyDetail.vue?vue&type=script&lang=js&"
export * from "./companyDetail.vue?vue&type=script&lang=js&"
import style0 from "./companyDetail.vue?vue&type=style&index=0&id=7a9194fe&lang=scss&scoped=true&"
import style1 from "./companyDetail.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a9194fe",
  null
  
)

export default component.exports